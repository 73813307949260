import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { GET_RECORDS_URL } from '../../config/api';

import {
    CopyIcon
} from 'lucide-react';
import { formatDate } from '../../config/utilFunction';


const DataTableKP = () => {

     // Handle search
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const fetchData = async (page,size= perPage,search='') => {
 
        const response = await axios.post(GET_RECORDS_URL, {
            page,
            limit:size,
            search
        });

        const result = response.data.records.rows;

            setData(result);
            setTotalRows(response.data.records.count);
            setLoading(false);

    };

        // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
        fetchData(page);
    };

      // Handle per page change
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handleSearch = async (search) => {
        setLoading(true);
        setFilterText(search);
        fetchData(1, perPage, search);
    };

    const handleCopy = (row) => {
        navigator.clipboard.writeText(row.WADFilePassword);
        alert('Password copied to clipboard');
    };

        useEffect(() => {
fetchData(1);
    }, []);


    // Define columns
    const columns = [
         {
            name: 'Customer ID',
            selector: row => row.CustomerId,
            sortable: false,
             width: '6%',
        },
        {
            name: 'Name',
            selector: row => row.CName,
            sortable: false,
             
        },
        {
            name: 'Attachment Type',
            selector: row => row.WADFileAttachmentType,
            sortable: false,
        },
    
        {
            name: 'File Name',
            selector: row => row.WADFileName,
            sortable: false,
         
        },
        
        {
            name: 'Password',
            selector: row => row.WADFilePassword,
            sortable: false,
            cell: (row) => (
                <div className="flex items-center" onClick={()=> handleCopy(row)}>
                    <CopyIcon size={16} />
                    <span className="ms-2">{row.WADFilePassword}</span>
                </div>
            ),
               width: '8%',
     
        },
        {
            name: 'Actions',
            cell: (row) => (
                <button 
                    onClick={() => handleAction(row)}
                    className="px-4 py-2 bg-blue-500 rounded"
                >
                    View File
                </button>
            ),
               width: '10%',
        },
        {
            name: 'Added On',
            selector: row => formatDate(row.createdAt),
            sortable: false,
              width: '6%',
        }
    ];

    // Custom styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                backgroundColor: '#f3f4f6',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    // Handle row selection
    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
    };

    // Handle action button click
    const handleAction = (row) => {
        console.log('Action clicked for row:', row);

        // Open URL in new tab
        window.open(row.WADFileURL, '_blank');
    };



    return (
        <div className="p-4 container-fluid">
            {/* Search input */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search by ID, Name, or File Name"
                    value={filterText}
                    style={{ width: '100%' }}
                    onChange={e => handleSearch(e.target.value)}
                    className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                />
            </div>
     <DataTable
       
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
                responsive
                highlightOnHover
                pointerOnHover
                striped
                persistTableHead
            />

            {/* Selected rows info */}
            {selectedRows.length > 0 && (
                <div className="mt-4 p-4 bg-blue-100 rounded">
                    <p>Selected {selectedRows.length} rows</p>
                </div>
            )}
        </div>
    );
};

export default DataTableKP;
import React, { createContext, useContext, useState } from 'react';

// Create context
const AppContext = createContext();

export function AppProvider({ children }) {
  // Define state that will be shared
  const [user, setUser] = useState({
    name: '',
    isAuthenticated: false,
  });

    // Create functions to modify state
  const login = (username) => {
    setUser({
      name: username,
      isAuthenticated: true,
    });

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 6);
      localStorage.setItem("user", JSON.stringify({
        name: username,
        isAuthenticated: true,
        expires: expirationDate.toISOString(),
      }));
  };

  const  checkAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      const expirationDate = new Date(userData.expires);
      if (expirationDate > new Date()) {
        setUser(userData);
      }
    }
  }

    const logout = () => {
    setUser({
      name: '',
      isAuthenticated: false,
    });
 localStorage.removeItem("user");
    };

    return (
    <AppContext.Provider value={{ user, login, logout, checkAuth }}>
      {children}
    </AppContext.Provider>
    );

}

export {
    AppContext
}
import React, {useContext, useEffect, useState} from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import "./login.css";
import { LOGIN_URL } from '../../config/api';
import axios from 'axios';


import { AppContext } from '../../context/appContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
    const [swalProps, setSwalProps] = useState({});
    const { user,login, checkAuth } = useContext(AppContext);
      const navigate = useNavigate();
  

  const handleSubmit = (e) => {
    e.preventDefault();
       console.log('User Contexte:', user);
    console.log('Login submitted:', { username, password });

    axios.post(LOGIN_URL, { email:username, password })
      .then((response) => {
        console.log('Login response:', response);
        login('Admin');


      
     
        navigate('/dashboard');
        

      })
      .catch((error) => {
        console.error('Login error:', error);
          setSwalProps({
                    show: true,
                    title: error.response.data.message,
                    icon:'error'
                });
        // Here you would typically handle the error, e.g. display the error message
      });




    // Here you would typically handle the login logic
  };

  useEffect(() => {
    checkAuth();

    if(user.isAuthenticated){
      navigate('/dashboard');
    }

  }, [checkAuth,user]);


  return (
   <Row>
     <Col>
     <div className='banner_wrapper'>
        <div className="banner_content">

             <img src="https://kanplas.jp/assets/img/common/logo.png" alt="kanplas" />
             <h3 className='pt-5'>Kanpur Plastipack</h3>

        </div>

     </div>
     </Col>
     <Col>
     <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <Form onSubmit={handleSubmit} className='loginForm'>

            <h2 className='pb-5'>Login</h2>
            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className='loginButton'>
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
     </Col>
        <SweetAlert2 {...swalProps} />
     </Row>
  )
}

export default Login;
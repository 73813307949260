import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AppContext } from '../../context/appContext';

const NavBar = () => {
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  
  const { user,logout } = useContext(AppContext);


  const handleLogout = () => {
    setShowLogoutModal(false);
    logout();
    navigate('/');
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="shadow-sm">
        <Container>
          {/* Left side - Logo */}
          <Navbar.Brand href="/" className="me-4">
            <Image
              src="https://kanplas.jp/assets/img/common/logo.png"
              alt="Company Logo"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>

        

          {/* Right side - User Info & Logout */}
          <div className="d-flex align-items-center">
            <span className="me-3">{user.name}</span>
            
            <Button 
              variant="outline-danger" 
              onClick={() => setShowLogoutModal(true)}
              className="rounded-pill px-4"
            >
              Logout
            </Button>
          </div>
        </Container>
      </Navbar>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavBar;
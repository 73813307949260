import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/appContext'
import { useNavigate } from 'react-router-dom'
import NavBar from '../../components/Navbar/Navbar'
import Stats from '../../components/Stats/Stats'
import DataTableKP from '../../components/Datatable/Datatable'


const Dashboard = () => {
    const { user } = useContext(AppContext)
    const navigate = useNavigate()
    useEffect(() => {
        if (user.isAuthenticated === false) {
            console.log("Redirect User",user)
            navigate('/')
        }
    }, [user, navigate])


  return (
    <div>
        <NavBar />
        <Stats /> 
        <DataTableKP/>
    </div>
  )
}

export default Dashboard
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Placeholder, Spinner } from 'react-bootstrap';
import { 
  RefreshCw,
  FilesIcon
} from 'lucide-react';
import { GET_STATS_URL } from '../../config/api';
import axios from 'axios';
import './Stats.css';

const StatsCard = ({ stat, isLoading }) => {
  if (isLoading) {
    return (
      <Card className="h-100 border-0 shadow-sm">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Placeholder animation="glow" className="rounded-circle p-3">
              <Placeholder xs={12} style={{ width: '24px', height: '24px' }} />
            </Placeholder>
            <Placeholder animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          </div>
          <Placeholder animation="glow">
            <Placeholder xs={6} size="lg" className="mb-1" />
            <Placeholder xs={8} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="h-100 border-0 shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div 
            className="rounded-circle p-3" 
            style={{ backgroundColor: stat.bgColor }}
          >
            <div className={`text-${stat.color}`}>
              <FilesIcon size={24} />
            </div>
          </div>
          <div className={`text-${stat.isPositive ? 'success' : 'danger'} d-flex align-items-center`}>
            <small className="fw-bold">
              {stat.change}
            </small>
          
          </div>
        </div>
        <div>
          <h3 className="fw-bold mb-1">{stat.value}</h3>
          <p className="text-muted mb-0">{stat.title}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

const Stats = () => {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    setIsLoading(true);
    try {
      
        const { data } = await axios.get(GET_STATS_URL);  
      
      setStats(data);
      setError(null);
    } catch (err) {
      setError('Failed to load stats');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <Container className="py-4" fluid>
      {/* Header with refresh button */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="mb-0">Dashboard</h2>
        <button 
          className="btn btn-light" 
          onClick={fetchStats}
          disabled={isLoading}
        >
          <RefreshCw 
            size={20} 
            className={isLoading ? 'spin' : ''} 
          />
        </button>
      </div>

      {/* Error message */}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {/* Stats cards */}
      <Row className="g-4">
        {(isLoading ? Array(2).fill({}) : stats).map((stat, index) => (
          <Col key={index} xs={12} sm={6} xl={6}>
            <StatsCard stat={stat} isLoading={isLoading} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Stats;
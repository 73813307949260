import "bootstrap/dist/css/bootstrap.min.css";
import { Container} from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./pages/login/Login.jsx";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import {  AppProvider } from "./context/appContext.js";


function App() {
  return (
    <div className="App">
      <AppProvider>
        <Container  fluid>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </Router>
        </Container>
      </AppProvider>
    </div>
  );
}

export default App;
